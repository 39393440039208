(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/ttl-cache/assets/javascripts/ttl-cache.js') >= 0) return;  svs.modules.push('/components/lb-utils/ttl-cache/assets/javascripts/ttl-cache.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const DEFAULT_OPTIONS = Object.freeze({
  ttl: 1000,
  max: Infinity
});
const MIN_SIZE = 1;
function validateMax(max) {
  if (max < MIN_SIZE) {
    throw new Error("Invalid max ".concat(max));
  }
}
class TTLCache {
  constructor(options) {
    _defineProperty(this, "cache", new Map());
    const {
      ttl,
      max
    } = _objectSpread(_objectSpread({}, DEFAULT_OPTIONS), options);
    if (ttl <= 0) {
      throw new Error("Invalid ttl ".concat(ttl));
    }
    validateMax();
    this.ttl = ttl;
    this.max = max;
  }
  size() {
    return this.cache.size;
  }
  has(key) {
    this.get(key);
    return this.cache.has(key);
  }
  get(key) {
    const entry = this.cache.get(key);
    if (!entry) {
      return;
    }
    if (this._isExpired(entry)) {
      this._unlink(entry);
      return;
    }
    this._bumpAge(entry);
    return entry.value;
  }
  set(key, value) {
    const existing = this.cache.get(key);
    if (existing) {
      existing.value = value;
      this._bumpAge(existing);
      return;
    }
    const entry = {
      key,
      value,
      expire: Date.now() + this.ttl,
      prev: this.newest,
      next: null
    };
    this.cache.set(entry.key, entry);
    if (this.newest) {
      this.newest.next = entry;
    } else {
      this.oldest = entry;
    }
    this.newest = entry;
    if (this.oldest && this.cache.size > this.max) {
      this._unlink(this.oldest);
    }
  }
  clear() {
    this.cache.clear();
    this.oldest = null;
    this.newest = null;
  }
  delete(key) {
    const entry = this.cache.get(key);
    if (!entry) {
      return;
    }
    const unlinked = this._unlink(entry);
    return unlinked.value;
  }
  cleanup() {
    while (this.oldest && this._isExpired(this.oldest)) {
      this._unlink(this.oldest);
    }
  }
  resize(max) {
    if (this.max === max) {
      return;
    }
    validateMax(max);
    this.max = max;
    while (this.oldest && this.cache.size > this.max) {
      this._unlink(this.oldest);
    }
  }
  _bumpAge(entry) {
    entry.expire = Date.now() + this.ttl;
    if (!entry.next) {
      return;
    }
    if (entry.prev) {
      entry.prev.next = entry.next;
    } else {
      this.oldest = entry.next;
    }
    entry.next.prev = entry.prev;
    entry.prev = this.newest;
    entry.next = null;
    this.newest.next = entry;
    this.newest = entry;
  }
  _unlink(entry) {
    this.cache.delete(entry.key);
    if (entry.prev) {
      entry.prev.next = entry.next;
    } else {
      this.oldest = entry.next;
    }
    if (entry.next) {
      entry.next.prev = entry.prev;
    } else {
      this.newest = entry.prev;
    }
    return entry;
  }
  _isExpired(entry) {
    return entry.expire < Date.now();
  }
}
setGlobal('svs.components.lbUtils.ttlCache.TTLCache', TTLCache);

 })(window);