(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/ttl-cache/assets/javascripts/ttl-cache-async.js') >= 0) return;  svs.modules.push('/components/lb-utils/ttl-cache/assets/javascripts/ttl-cache-async.js');
"use strict";


const isServer = typeof exports === 'object';

const trinidad = isServer ? require('trinidad-core').core : svs.core;

const getLogger = isServer ? trinidad.logger : svs.core.log.getLogger;
const logger = getLogger('banner_layouts:griditem-quickplay');

class TTLCacheAsync {
  constructor(_ref) {
    let {
      onRefreshAsync,
      ttl,
      onError = null,
      timeoutMS = null
    } = _ref;
    this._onRefreshAsync = onRefreshAsync;
    this._ttl = ttl;
    this._cache = {};
    this._onError = onError;
    this._timeoutMS = timeoutMS;
  }

  async readAsync(_ref2) {
    let {
      key,
      payload
    } = _ref2;
    if (this._cache[key]) {
      const {
        lastUpdateTS,
        data
      } = this._cache[key];
      if (Date.now() < lastUpdateTS + this._ttl) {
        return data;
      }
    }
    await new Promise(resolve => {
      let timer;
      let hasResolved = false;
      const _resolveWithoutOrBeforeTimeout = () => {
        if (!hasResolved) {
          if (timer) {
            clearTimeout(timer);
          }
          hasResolved = true;
          resolve(null);
        }
      };
      const _resolveAfterTimeout = () => {
        if (!hasResolved) {
          logger.info("TTLCacheAsync: Timeout after ".concat(this._timeoutMS, " milliseconds (key \"").concat(key, "\")"));
          hasResolved = true;
          resolve(null);
        }
      };
      if (this._timeoutMS && this._timeoutMS > 0) {
        timer = setTimeout(_resolveAfterTimeout, this._timeoutMS);
      }
      this._refreshAsync({
        key,
        payload,
        resolve: _resolveWithoutOrBeforeTimeout
      });
    });
    return this._cache[key].data;
  }

  async _refreshAsync(_ref3) {
    let {
      key,
      payload,
      resolve
    } = _ref3;
    const {
      isAlreadyRefreshing
    } = this._cache[key] || {};
    if (isAlreadyRefreshing) {
      this._cache[key].pendingResolvers.push(resolve);
      return;
    }
    if (!this._cache[key]) {
      this._cache[key] = {};
    }
    this._cache[key].isAlreadyRefreshing = true;
    this._cache[key].pendingResolvers = [resolve];
    try {
      this._cache[key].data = await this._onRefreshAsync({
        key,
        payload
      });
    } catch (error) {
      if (this._onError) {
        this._onError(error);
      }
    }
    this._cache[key].lastUpdateTS = Date.now();
    this._cache[key].isAlreadyRefreshing = false;
    this._cache[key].pendingResolvers.splice(0).forEach(item => item());
  }
}
if (isServer) {
  module.exports = {
    TTLCacheAsync
  };
} else {
  setGlobal('svs.components.lbUtils.ttlCache.TTLCacheAsync', TTLCacheAsync);
}

 })(window);